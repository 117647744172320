import * as React from 'react';
import { Col, Container, FormControl, Row, Table } from 'react-bootstrap';
import { getRequest } from '../../../Helper/ApiClient';
import { formatCurrencyEur } from '../../../Helper/formatter';
import AddAllToBasketButton from './AddAllToBasketButton/AddAllToBasketButton';
import { useNcw } from '../Context/NcwContext';
import PriceSection from './PriceSection/PriceSection';

export interface NcwFlavourLine {
  id: number; // ncwProductAttribute->id
  brand_id: number;
  brand_erpId: number;
  brand_name: string;
  product_id: number;
  product_erpId: number;
  product_name: string;
  productSize_id: number;
  productSize_erpId: number;
  productSize_name: string;
  productAttribute_id: number;
  productAttribute_erpId: number;
  productAttribute_name: string;
  promotion_discount: number;
  regular_price: number;
  price: number;
}

export interface NcwProductAttributeQty {
  [id: string]: {
    qty: number;
    price: number;
  };
}
export default function FlavourList() {
  const flavoursRequest = getRequest<NcwFlavourLine[]>(
    '/api/onlineshop/newcomer-week/flavour-list'
  );
  const [qtyPerId, setQtyPerId] = React.useState<NcwProductAttributeQty>({});

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.currentTarget.name;
    const qty = Number(e.currentTarget.value);
    const price = Number(e.currentTarget.dataset.price);

    setQtyPerId((prevQtyPerId) => {
      const updatedQtyPerId = { ...prevQtyPerId };
      if (qty === 0) {
        delete updatedQtyPerId[id!];
      } else {
        updatedQtyPerId[id!] = { qty, price };
      }
      return updatedQtyPerId;
    });
  };

  const calculateTotalQty = (): number => {
    return Object.values(qtyPerId).reduce(
      (totalQty, item) => totalQty + item.qty,
      0
    );
  };

  const calculateTotalPrice = (): number => {
    return Object.values(qtyPerId).reduce(
      (totalPrice, item) => totalPrice + item.qty * item.price,
      0
    );
  };

  const ncwContext = useNcw();
  const refetchPage = () => {
    flavoursRequest.refetch();
    setQtyPerId({});
    ncwContext.refetchNcwBasketHeader();
  };
  return (
    <Container className={'py-5'}>
      <Row className={'pb-2'}>
        <Col>
          <h1>Order by article</h1>
        </Col>
      </Row>
      <Row className={'d-md-none'}>
        <Col>
          {flavoursRequest?.data?.map((line) => (
            <div key={line.id} className={'pb-3'}>
              <div className={'fw-bold'}>{line.brand_name}</div>
              <div>{line.productSize_name}</div>
              <div>{line.productAttribute_name}</div>
              <div>
                <PriceSection line={line} />
              </div>
              <div>
                <FormControl
                  type="number"
                  name={`${line.id}`}
                  onChange={onChange}
                  min={0}
                  data-price={line.price}
                  value={qtyPerId[line.id]?.qty ? qtyPerId[line.id].qty : ''}
                />
              </div>
            </div>
          ))}
        </Col>
      </Row>
      <Row className={'d-none d-md-block'}>
        <Col>
          <Table>
            <thead>
              <tr className={'fw-bold'}>
                <td>Brand</td>
                <td>Product</td>
                <td>Flavour</td>
                <td className="text-end">Price</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {flavoursRequest?.data?.map((line) => (
                <tr key={line.id}>
                  <td className="align-middle">{line.brand_name}</td>
                  <td className="align-middle">{line.productSize_name}</td>
                  <td className="align-middle">{line.productAttribute_name}</td>
                  <td className="text-end align-middle">
                    <PriceSection line={line} />
                  </td>
                  <td className="align-middle">
                    <FormControl
                      type="number"
                      name={`${line.id}`}
                      onChange={onChange}
                      min={0}
                      data-price={line.price}
                      value={
                        qtyPerId[line.id]?.qty ? qtyPerId[line.id].qty : ''
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div className="fixed-bottom bg-white p-4" style={{ zIndex: 1 }}>
        <Container>
          <Row>
            <Col className="text-end">
              <span className="me-4">Units: {calculateTotalQty()}</span>
              <span className="me-4">
                Total: {formatCurrencyEur(calculateTotalPrice())}
              </span>
              <AddAllToBasketButton
                ncwProductAttributeQty={qtyPerId}
                onSuccess={refetchPage}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
}
