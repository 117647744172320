import * as React from 'react';
import { Badge } from 'react-bootstrap';
import { formatCurrencyEur } from '../../../../Helper/formatter';
import { NcwFlavourLine } from '../FlavourList';
export default function PriceSection({line}: {line: NcwFlavourLine}) {
  return (
    <>
      <Badge bg="danger">{`GET ${line.promotion_discount}% OFF!`}</Badge>
      <br />
      <small>
        <del>{formatCurrencyEur(line.regular_price)}</del>
      </small>
      <div className="fw-bold align-middle text-danger">
        {formatCurrencyEur(line.price)}
      </div>
    </>
  );
}
